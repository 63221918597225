<template>
    <div>
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th @click="sortedByItem('name')" :class="{ active: sortingDesc && sortedBy == 'name' }">
                        <div class="">
                            Номи
                            <svg width="10px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                <path
                                    d="M209.4 39.4C204.8 34.7 198.6 32 192 32s-12.8 2.7-17.4 7.4l-168 176c-9.2 9.6-8.8 24.8 .8 33.9s24.8 8.8 33.9-.8L168 115.9V456c0 13.3 10.7 24 24 24s24-10.7 24-24V115.9L342.6 248.6c9.2 9.6 24.3 9.9 33.9 .8s9.9-24.3 .8-33.9l-168-176z"
                                />
                            </svg>
                        </div>
                    </th>
                    <th @click="sortedByItem('size')" :class="{ active: sortingDesc && sortedBy == 'size' }">
                        Ўлчами
                        <svg width="10px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                            <path
                                d="M209.4 39.4C204.8 34.7 198.6 32 192 32s-12.8 2.7-17.4 7.4l-168 176c-9.2 9.6-8.8 24.8 .8 33.9s24.8 8.8 33.9-.8L168 115.9V456c0 13.3 10.7 24 24 24s24-10.7 24-24V115.9L342.6 248.6c9.2 9.6 24.3 9.9 33.9 .8s9.9-24.3 .8-33.9l-168-176z"
                            />
                        </svg>
                    </th>
                    <th></th>
                </tr>
            </thead>

            <tbody>
                <tr v-for="item in sortedItems">
                    <td>{{ item.name }}</td>
                    <td>{{ item.size | prettyBytes }}</td>
                    <td>
                        <i
                            @click="download(item)"
                            v-b-tooltip="'Юклаб олиш'"
                            class="fa fa-download cursor-pointer mx-1"
                        ></i>
                        <i
                            @click="copyLink(item)"
                            v-b-tooltip="'Жойлашув манзилини кўчириб олиш'"
                            class="fa fa-link cursor-pointer mx-1"
                        ></i>
                        <i
                            @click="deleteFileDialog(item.path, item.name)"
                            v-b-tooltip="'Файлни ўчириш'"
                            class="fa fa-trash cursor-pointer mx-1"
                        ></i>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { computed } from "vue"
import pathJoin from "@Platon/components/file-manager/pathJoin"
import FileManagerDialogsMixin from "@Platon/components/file-manager/FileManagerDialogsMixin"
import { API_BASE_URL } from "@Platon/const"

export default {
    name: "FileTable",

    mixins: [FileManagerDialogsMixin],

    inject: ["fm"],

    props: {
        items: {}
    },

    data() {
        return {
            sortedBy: "",
            sortingDesc: false,
            rotateDesc: false
        }
    },

    created() {
        this.setInitialSorts()
    },

    computed: {
        sortedItems() {
            return this.items.sort((a, b) => {
                let sortingAsc = a[this.sortedBy] > b[this.sortedBy]
                if (this.sortingDesc) return sortingAsc ? -1 : 1
                return sortingAsc ? 1 : -1
            })
        }
    },
    methods: {
        setInitialSorts() {
            const storedData = JSON.parse(
                localStorage.getItem("file_manager_sorts") ||
                    JSON.stringify({
                        sortedBy: "name"
                    })
            )
            this.sortedBy = storedData.sortedBy
            this.sortingDesc = storedData.sortingDesc
        },
        /**
         * @param {PlatonFMFile} item
         */
        download(item) {
            window.open(this.getUrl(item), "_blank")
        },

        sortedByItem(item) {
            this.sortedBy = item
            this.sortingDesc = !this.sortingDesc
            localStorage.setItem(
                "file_manager_sorts",
                JSON.stringify({ sortedBy: item, sortingDesc: this.sortingDesc })
            )
        },

        /**
         * @param {PlatonFMFile} item
         */
        copyLink(item) {
            navigator.clipboard.writeText(this.getUrl(item))
            this.$platonApp.greenToast(this.$l("platon.toast_success_moved", "Кўчирилди"))
        },

        /**
         * @param {PlatonFMFile} item
         */
        getUrl(item) {
            return pathJoin(this.$const.API_BASE_URL, "web/v1/store/file", item.path, item.name)
        }
    }
}
</script>
